import React from "react";
import { Link } from "react-router-dom";
import { getPaginationMessage } from "../../utils/common";

const Pagination = ({
  setCurrentPage,
  TotalPages,
  currentPage,
  TotalEntries,
  perPage,
}) => {
  const handlePrevious = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNext = () => {
    if (currentPage < TotalPages) {
      setCurrentPage(currentPage + 1);
    }
  };
  return (
    <div className="row">
      <div className="col-md-6 ">
        <div className="entries-section justify-content-left">
          <div className="entries-section">
            <p>{getPaginationMessage(TotalEntries, perPage, currentPage)} </p>
          </div>
          {/* <div className="entries-section">
                              Show &nbsp;
                              <select>
                                  <option>10</option>
                                  <option>25</option>
                                  <option>50</option>
                                  <option>100</option>
                              </select>
                              &nbsp; entries
                          </div> */}
        </div>
      </div>
      <div className="col-md-6 mt-2">
        <div className="justify-content-left">
          <nav aria-label="Page navigation example">
            <ul className="pagination justify-content-md-end justify-content-start">
              <li
                className={`page-item ${currentPage === 1 ? "disabled" : ""}`}
              >
                <Link className="page-link" onClick={handlePrevious} to="#">
                  Previous
                </Link>
              </li>
              <li
                className={`page-item ${
                  currentPage === TotalPages ? "disabled" : ""
                }`}
              >
                <Link className="page-link" onClick={handleNext} to="#">
                  Next
                </Link>
              </li>
            </ul>
            {/* <ul className="pagination justify-content-md-end justify-content-start">
            <li className="page-item">
              <Link className="page-link">Previous</Link>
            </li>

            <li className="page-item">
              <Link className="page-link">Next</Link>
            </li>
          </ul> */}
          </nav>
        </div>
      </div>
    </div>
  );
};

export default Pagination;
