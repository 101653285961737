import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faPerson, fas } from "@fortawesome/free-solid-svg-icons";
import "./Sidebar.css";
import { Accordion } from "react-bootstrap";

library.add(fas);

const Sidebar = ({ isOpen }) => {
  const [activeAccordionItem, setActiveAccordionItem] = useState("1");

  const location = useLocation();
  const [headerText, setHeaderText] = useState(location.pathname);

  useEffect(() => {
    setHeaderText(location.pathname);
  }, [location.pathname]);

  const handleAccordionClick = (itemKey) => {
    setActiveAccordionItem(itemKey);
  };

  return (
    <aside className={`sidebar ${isOpen ? "open" : ""}`}>
      <ul>
        <li
          className={`mb-1 ${activeAccordionItem === "1" ? "active" : ""}`}
          onClick={() => handleAccordionClick("1")}
        >
          <Link to="/dashboard" className="link-body-emphasis">
            <div className="icon-text">
              <FontAwesomeIcon icon="fa-solid fa-gauge" className="icon" />
              {isOpen && <p>Dashboard</p>}
            </div>
          </Link>
        </li>

        <Accordion>
          <Accordion.Item eventKey="8">
            <Accordion.Header>
              <div className="icon-text">
                <FontAwesomeIcon
                  icon={["fas", "credit-card"]}
                  className="icon me-2"
                />
                {isOpen && <p>Setting</p>}
              </div>
            </Accordion.Header>
            <Accordion.Body>
              <ul className="list-unstyled">
                <li
                  className={`setting ${
                    activeAccordionItem === "9" ? "active" : ""
                  }`}
                  onClick={() => handleAccordionClick("9")}
                >
                  <Link to="/masters/app-setup" className="link-body-emphasis">
                    <FontAwesomeIcon
                      icon="fa-solid fa-globe"
                      className="icon me-2"
                    />
                    {isOpen && <p>App SetUp</p>}
                  </Link>
                </li>
                
                <li className="setting">
                  <Link to="/pnl-client" className="link-body-emphasis">
                    <FontAwesomeIcon
                      icon="fa-solid fa-globe"
                      className="icon me-2"
                    />
                    {isOpen && <p>Pnl Client</p>}
                  </Link>
                </li>
                <li className="setting">
                  <Link to="/pnl-transaction" className="link-body-emphasis">
                    <FontAwesomeIcon
                      icon="fa-solid fa-flag-usa"
                      className="icon me-2"
                    />

                    {isOpen && <p>Pnl Transaction</p>}
                  </Link>
                </li>
                {/* <li className="setting">
                  <Link to="/masters/city" className="link-body-emphasis">
                    <FontAwesomeIcon
                      icon="fa-solid fa-city"
                      className="icon me-2"
                    />

                    {isOpen && <p>City</p>}
                  </Link>
                </li> */}
                {/* <li className="setting">
                  <Link to="/masters/pincode" className="link-body-emphasis">
                    <FontAwesomeIcon
                      icon="fa-solid fa-hashtag"
                      className="icon me-2"
                    />

                    {isOpen && <p>Pincode</p>}
                  </Link>
                </li> */}
                {/* <li className="setting">
                  <Link to="/masters/occupation" className="link-body-emphasis">
                    <FontAwesomeIcon
                      icon="fa-solid fa-user-doctor"
                      className="icon me-2"
                    />
                    {isOpen && <p>Occupation</p>}
                  </Link>
                </li> */}
                <li
                  className={`setting ${
                    activeAccordionItem === "10" ? "active" : ""
                  }`}
                  onClick={() => handleAccordionClick("10")}
                >
                  <Link to="/masters/faq" className="link-body-emphasis">
                    <FontAwesomeIcon
                      icon="fa-solid fa-user-doctor"
                      className="icon me-2"
                    />
                    {isOpen && <p>FAQ</p>}
                  </Link>
                </li>
              </ul>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
        <li
          className={`mb-1 ${activeAccordionItem === "2" ? "active" : ""}`}
          onClick={() => handleAccordionClick("2")}
        >
          <Link to="/investor" className="link-body-emphasis">
            <div className="icon-text">
              <FontAwesomeIcon icon="fa-solid fa-money-bill" className="icon" />
              {isOpen && <p>Investor</p>}
            </div>
          </Link>
        </li>
        <li
          className={`mb-1 ${activeAccordionItem === "3" ? "active" : ""}`}
          onClick={() => handleAccordionClick("3")}
        >
          <Link to="/investor-ledger" className="link-body-emphasis">
            <div className="icon-text">
              <FontAwesomeIcon icon={faPerson} className="icon" />
              {isOpen && <p>Investor Ledger</p>}
            </div>
          </Link>
        </li>
        <li
          className={`mb-1 ${activeAccordionItem === "4" ? "active" : ""}`}
          onClick={() => handleAccordionClick("4")}
        >
          <Link to="/operations-main" className="link-body-emphasis">
            <div className="icon-text">
              <FontAwesomeIcon icon="fa-solid fa-gear" className="icon" />
              {isOpen && <p>Operation</p>}
            </div>
          </Link>
        </li>
        <li
          className={`mb-1 ${activeAccordionItem === "5" ? "active" : ""}`}
          onClick={() => handleAccordionClick("5")}
        >
          <Link to="/inward" className="link-body-emphasis">
            <div className="icon-text">
              <FontAwesomeIcon
                icon="fa-solid fa-credit-card"
                className="icon"
              />
              {isOpen && <p>Inward Payment</p>}
            </div>
          </Link>
        </li>
        <li
          className={`mb-1 ${activeAccordionItem === "6" ? "active" : ""}`}
          onClick={() => handleAccordionClick("6")}
        >
          <Link to="/outward" className="link-body-emphasis">
            <div className="icon-text">
              <FontAwesomeIcon icon="fa-solid fa-outdent" className="icon" />
              {isOpen && <p>Outward Payment</p>}
            </div>
          </Link>
        </li>
        <li
          className={`mb-1 ${activeAccordionItem === "7" ? "active" : ""}`}
          onClick={() => handleAccordionClick("7")}
        >
          <Link to="/notification" className="link-body-emphasis">
            <div className="icon-text">
              <FontAwesomeIcon icon="fa-solid fa-bell" className="icon" />
              {isOpen && <p>Notification</p>}
            </div>
          </Link>
        </li>

        <li
          className={`mb-1 ${activeAccordionItem === "8" ? "active" : ""}`}
          onClick={() => handleAccordionClick("8")}
        >
          <Link to="/rate-us" className="link-body-emphasis">
            <div className="icon-text">
              <FontAwesomeIcon icon="fa-solid fa-bell" className="icon" />
              {isOpen && <p>Rate Us</p>}
            </div>
          </Link>
        </li>

        <li
          className={`mb-1 ${activeAccordionItem === "9" ? "active" : ""}`}
          onClick={() => handleAccordionClick("9")}
        >
          <Link to="/pnl" className="link-body-emphasis">
            <div className="icon-text">
              <FontAwesomeIcon icon="fa-solid fa-bell" className="icon" />
              {isOpen && <p>PnL Client Transactions</p>}
            </div>
          </Link>
        </li>
        {/* <li
          className={`mb-1 ${activeAccordionItem === "8" ? "active" : ""}`}
          onClick={() => handleAccordionClick("8")}
        >
          <Link to="/notification" className="link-body-emphasis">
            <div className="icon-text">
              <FontAwesomeIcon icon="fa-solid fa-bell" className="icon" />
              {isOpen && <p>Setting</p>}
            </div>
          </Link>
        </li> */}
      </ul>
    </aside>
  );
};

export default Sidebar;
