import React, { useContext } from "react";
import { useState, useEffect } from "react";
import "../../Tabels/Tabels.css";
import { useForm } from "react-hook-form";

import Header from "../../Header/Header";
import { Context } from "../../../../utils/context";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faPerson, faWarehouse, fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Button, Col, Row } from "react-bootstrap";
import Datepicker from "../../../common/Datepicker";
import { getAllData } from "../../../../utils/apis/lnvestorsledger/lnvestorsledger";
import { formatDate } from "../../../../utils/common";
import ExportMethod from "../../../../utils/apis/ExportMethod";

library.add(fas);

// *****************************************************************************************************

const  Tables = () => {
  const {
    control,
    register,
    handleSubmit,
    getValues,
    setError,
    clearErrors,
    reset,
    formState: { errors },
    setValue,
  } = useForm({
    defaultValues: {
      from: "",
      to: "",
      service_number: "",
    },
  });

  const onSubmit = async (data) => {
    getData();
  };

  const [state, setState] = useState([]);
  const [occupation, setOccupation] = useState([]);
  const [city, setCity] = useState([]);
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [TotalEntries, setTotalEntries] = useState(1);
  const [TotalPages, setTotalPages] = useState(1);
  const [perPage, setperPage] = useState(10);

  const getData = async () => {
    const res = await getAllData(
      getValues("service_number"),
      getValues("from"),
      getValues("to"),
      currentPage,
      perPage
    );
    setData(res.data);
    setCurrentPage(res?.data?.currentPage);
    setperPage(res?.data?.per_page);
    setTotalEntries(res?.data?.totalEntries);
    setTotalPages(res?.data?.totalPages);
  };

  useEffect(() => {
    getData();
  }, [perPage, currentPage]);

  //
  const handleExportCSV = () => {
    const header = [
      { name: "Transaction Date" },
      { name: "Transaction segment" },
      { name: "Transaction Debit" },
      { name: "Transaction Credit" },
      { name: "Balance Amount" },
      { name: "Profit & Loss Amount" },
    ];
    const exportData = data?.wallet_histories?.map((data) => [
      formatDate(data.createdAt),
      data?.type,
      data?.t_d || 0,
      data?.t_c || 0,
      data?.amount || 0,
      data?.profit_loss || 0,
    ]);
    const { content, type, name } = ExportMethod.csv(
      exportData,
      header,
      "Investor Ledger"
    );
    const blob = new Blob([content], { type });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = name;
    link.click();
  };

  const handleExportExcel = () => {
    const header = [
      { name: "Transaction Date" },
      { name: "Transaction segment" },
      { name: "Transaction Debit" },
      { name: "Transaction Credit" },
      { name: "Balance Amount" },
      { name: "Profit & Loss Amount" },
    ];
    const exportData = data?.wallet_histories?.map((data) => [
      formatDate(data.createdAt),
      data?.type,
      data?.t_d || 0,
      data?.t_c || 0,
      data?.amount || 0,
      data?.profit_loss || 0,
    ]);
    const { content, type, name } = ExportMethod.excel(
      exportData,
      header,
      "Investor Ledger"
    );
    const blob = new Blob([content], { type });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = name;
    link.click();
  };

  const handlePrint = () => {
    const header = [
      { name: "Transaction Date" },
      { name: "Transaction segment" },
      { name: "Transaction Debit" },
      { name: "Transaction Credit" },
      { name: "Balance Amount" },
      { name: "Profit & Loss Amount" },
    ];
    const exportData = data?.wallet_histories?.map((data) => [
      formatDate(data.createdAt),
      data?.type,
      data?.t_d || 0,
      data?.t_c || 0,
      data?.amount || 0,
      data?.profit_loss || 0,
    ]);
    const content = ExportMethod.print(exportData, header);
    const printWindow = window.open("", "_blank");
    printWindow.document.write(content);
    printWindow.document.close();
    printWindow.print();
  };

  return (
    <>
      <div className="main-advancedashboard">
        <div className="investor">
          <Header
            icon={faWarehouse}
            title={"Investor Ledger"}
            link={"/payment/inward-payment"}
          />
          <div className="search-investor ">
            <p className="sub">Search Investor Transaction</p>
            <Row>
              <Col xxl={9} xl={9} lg={12}>
                <div className="search-transaction mt-3">
                  <Row>
                    <Col xxl={4} xl={4} lg={4}>
                      <div className="main-inves">
                        <div className="inestement-main">
                          <div>
                          <b>Total Funds</b>
                            <p>{data?.amount}</p>
                          </div>
                        </div>
                      </div>
                    </Col>
                    {/* <Col xxl={3} xl={3} lg={4}>
                      <div className="main-inves">
                        <div className="inestement-main">
                          <div>
                            Active Investment
                            <p>200</p>
                          </div>
                        </div>
                      </div>
                    </Col> */}
                    <Col xxl={4} xl={4} lg={4}>
                      <div className="main-inves">
                        <div className="inestement-main">
                          <div>
                            <b> Today Earning</b>
                            <div className="row mt-1">
                              <Col xxl={6} xl={6} lg={6}>
                              <b> <p>Profit</p></b>
                                <p>{data?.profit_loss}</p>
                              </Col>
                              <Col xxl={6} xl={6} lg={6}>
                              <b> <p>Referral</p></b>
                                <p>{data?.total_referral_earn}</p>
                              </Col>
                            </div>

                          </div>
                        </div>
                      </div>
                    </Col>

                    <Col xxl={4} xl={4} lg={4}>
                      <div className="main-inves">
                        <div className="inestement-main">
                          <div>
                          <b>Total Withdrawal</b>
                            <div className="row mt-1">
                              <Col xxl={6} xl={6} lg={6}>
                              <b> <p>Profit</p></b>
                                <p>{data?.profit_loss}</p>
                              </Col>
                              <Col xxl={6} xl={6} lg={6}>
                              <b> <p>Referral</p></b>
                                <p>{data?.profit_loss}</p>
                              </Col>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                    {/* <Col xxl={3} xl={3} lg={4}>
                      <div className="main-inves">
                        <div className="inestement-main">
                          <div>
                            Referral Earning
                            <p>756</p>
                          </div>
                        </div>
                      </div>
                    </Col> */}
                  </Row>
                </div>
              </Col>
              <Col xxl={3} xl={3} lg={4}>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <p className="sub">Service Number</p>
                  <div>
                    <input
                      type="text"
                      id="firstName"
                      {...register("service_number", {
                        required: true,
                      })}
                      className={`form-control ${errors.service_number ? "is-invalid" : ""
                        }`}
                    />
                  </div>
                </form>
                <Datepicker
                  reset={reset}
                  getValues={getValues}
                  setValue={setValue}
                  className="mt-3"
                />
                <div className="d-flex">
                  <div className="search" onClick={handleSubmit(onSubmit)}>
                    <FontAwesomeIcon icon="fa-solid fa-magnifying-glass" />
                    <p>Search</p>
                  </div>
                  <div
                    className="search ms-1"
                    onClick={() => {
                      reset();
                      getData();
                    }}
                  >
                    {/* <FontAwesomeIcon icon="fa-solid fa-magnifying-glass" /> */}
                    <p>Reset</p>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          <div className="search-investor ">
            <div className="investor-main">
              <FontAwesomeIcon className="me-1" icon={faPerson} />
              <p className="sub">
                Name:{data?.f_name} {data?.l_name}
              </p>
            </div>

            {data && (
              <div className="nav-link active mt-4">
                <div className="dt-buttons btn-group flex-wrap">
                  <button
                    className="btn btn-secondary buttons-excel buttons-html5 ml-1 Excel_btn"
                    tabindex="0"
                    aria-controls="DataTables_Table_0"
                    type="button"
                    onClick={handleExportExcel}
                  >
                    <span>Excel</span>
                  </button>
                  <button
                    className="btn btn-secondary buttons-csv buttons-html5 CSV_btn"
                    tabindex="0"
                    aria-controls="DataTables_Table_0"
                    type="button"
                    onClick={handleExportCSV}
                  >
                    <span>CSV</span>
                  </button>
                  <button
                    className="btn btn-secondary buttons-print Print_btn"
                    tabindex="0"
                    aria-controls="DataTables_Table_0"
                    type="button"
                    onClick={handlePrint}
                  >
                    <span>Print</span>
                  </button>

                  
                {/* <input type="number" className="ps-3" onChange={(e) => setperPage(e.target.value)} placeholder="Enter Per Page Data"></input> */}
                </div>
              </div>
            )}

            <div className="table-main">
              <table class="table table-striped table-hover">
                <thead>
                  <tr>
                    <th scope="col " className="transaction">
                      Transaction Date
                    </th>
                    <th scope="col " className="transaction">
                      Transaction segment
                    </th>
                    <th scope="col" className="transaction">
                      Transaction Debit
                    </th>
                    <th scope="col" className="transaction">
                      Transaction Credit
                    </th>
                    <th scope="col" className="transaction">
                      Balance Amount
                    </th>
                    <th scope="col" className="transaction">
                      Profit & Loss Amount
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {data?.wallet_histories?.map((data, index) => (
                    <tr>
                      <th scope="row">{formatDate(data?.createdAt)}</th>
                      <td>{data?.type}</td>
                      <td>{data?.t_d}</td>
                      <td>{data?.t_c}</td>
                      <td>{data?.amount}</td>
                      <td>{data?.profit_loss}</td>
                    </tr>
                  ))}

                  {/* <tr>
                    <th scope="row">5 june 2024</th>
                    <td>Profit</td>
                    <td>400</td>
                    <td>3434</td>
                    <td>7856</td>
                  </tr>
                  <tr>
                    <th scope="row">5 june 2024</th>
                    <td colspan="2">Profit Withdrawal</td>
                    <td>5767</td>
                    <td>7856</td>
                  </tr>
                  <tr>
                    <th scope="row">5 june 2024</th>
                    <td colspan="2">Operator Charges</td>
                    <td>689</td>
                    <td>7856</td>
                  </tr>
                  <tr>
                    <th scope="row">5 june 2024</th>
                    <td colspan="2">Operator Charges</td>
                    <td>2423</td>
                    <td>7856</td>
                  </tr> */}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Tables;
